import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Components/Layout';
import ProjectTile from '../Components/ProjectTile';
import MasonryLayout from '../Components/MasonryLayout';

const games = ({ data }) => {

  const gameList = data.allMdx.edges.map(gameProject => {
    return (
      <ProjectTile 
        link={gameProject.node.slug}
        title={gameProject.node.frontmatter.title} 
        tileImage={gameProject.node.frontmatter.tileImage}
        alt={gameProject.node.frontmatter.tileAlt}
        id={gameProject.node.id}
        key={gameProject.node.id}
      />
    )
  })

  return (
    <Layout>
      <MasonryLayout content={gameList} />
      <article className='project-page'>
        <a 
          href="https://mattumland.itch.io/"
          className="project-page-link" 
          target={"_blank"}
          rel="noopener noreferrer"
        >See more at Itch.io</a>
      </article>
    </Layout>
  );
}

export default games; 

export const data = graphql` {
  allMdx(
    sort: {fields: [frontmatter___order], order: DESC}
    filter: {frontmatter: {tags: {eq: "game"}}}
  ) {
    edges {
      node {
        slug
        id
        frontmatter {
          title
          tileAlt
          tileImage {
            childImageSharp {
              gatsbyImageData(width: 1000, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
}`
